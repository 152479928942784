import React from 'react';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import ContentContainer from '../components/ContentContainer';
import Layout from '../components/layout';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { Box, Flex, Text } from '@chakra-ui/core';
import styled from 'styled-components';
import theme, { colors } from '@theme';
import canUseDOM from '@lib/canUseDOM';

const PageStyles = styled.div`
  font-size: 16px;
  line-height: 1.7;
  h1 {
    font-size: 2.25rem;
    line-height: 2.25rem;
    margin: 2.5rem 0;
  }

  h2 {
    font-size: 1.75rem;
    line-height: 1.75rem;
    margin: 2rem 0;
  }

  h3 {
    font-size: 1.5rem;
    line-height: 1.5rem;
    margin: 2rem 0;
  }

  h4 {
    font-size: 1.5rem;
    line-height: 1.5rem;
    margin: 1.5rem 0;
  }

  h5 {
    font-size: 1rem;
    line-height: 1rem;
  }

  h6 {
    font-size: 1rem;
    line-height: 1rem;
  }

  p {
    margin-bottom: 1rem;
    margin-top: 1rem;
    min-height: 1rem;
  }

  em {
    font-weight: 300;
  }

  a {
    color: ${colors.shared};
  }

  a:visited {
    color: ${colors.shared};
  }

  ul,
  ol {
    padding-left: 2rem;
    li {
      margin-bottom: 1rem;
    }
  }

  table thead tr {
    border-bottom: 1px solid ${colors.lightGray};
  }

  table thead tr th {
    text-align: left;
    font-size: 1rem;
    font-weight: 700;
    padding: 0 0.5rem 0.5rem 0.5rem;
    text-align: center;
  }

  table tbody tr td {
    padding: 1rem 0.5rem 0.5rem 0.5rem;
    text-align: center;
  }
`;

const Page = props => {
  const page = props.data.contentfulPage;
  const { body, title, slug, metaTitle, metaDescription } = page;
  const isWebview = canUseDOM ? window.location.search.includes('webview=true') : false;
  const isMobile = slug.includes('mobile');
  const output = documentToReactComponents(body.json);
  return (
    <>
      <Helmet>
        <title>{metaTitle?.length ? metaTitle : `${title} - Zeta`}</title>
        <link rel="canonical" href={`https://www.askzeta.com/${slug}`} />
        <meta name="description" content={metaDescription} />
        <meta name="og:description" content={metaDescription} />
      </Helmet>
      <Layout newNav legal mode={'home'} location={props.location} isMobile={isMobile}>
        <ContentContainer style={{ background: '#fff' }}>
          <Flex direction="row" className="wrapper" mt={['6rem', '6rem']} px={4}>
            <Box flex="1" pb={6} mb={6} style={{ minHeight: '11rem' }}>
              <Flex direction="column">
                {!isMobile && (
                  <Text
                    as="h1"
                    fontSize="3xl"
                    fontWeight="bold"
                    mb={6}
                    className="section-headline"
                    color={theme.colors.primary}
                  >
                    {title}
                  </Text>
                )}
                <PageStyles>
                  {output.map((rendered, idx) => {
                    try {
                      const child = rendered.props.children[0];
                      if (typeof child === 'string' && child.indexOf('<table>') !== -1) {
                        return (
                          <div
                            style={{
                              border: '1px solid #333',
                              padding: '1rem',
                              textAlign: 'center',
                            }}
                            dangerouslySetInnerHTML={{
                              __html: child,
                            }}
                            key={`child-${idx}`}
                          />
                        );
                      } else if (
                        typeof child === 'string' &&
                        child.indexOf('zeta-site-assets') !== -1
                      ) {
                        return <img src={child} key={`child-${idx}`} />;
                      } else {
                        return <div key={`child-${idx}`}>{rendered}</div>;
                      }
                    } catch (e) {
                      return <div key={`child-${idx}`}>{rendered}</div>;
                    }
                  })}
                </PageStyles>
              </Flex>
            </Box>
          </Flex>
        </ContentContainer>
      </Layout>
    </>
  );
};

export default Page;

export const pageQuery = graphql`
  query PageQuery($slug: String!) {
    contentfulPage(slug: { eq: $slug }) {
      title
      metaTitle
      metaDescription
      slug
      body {
        json
      }
    }
  }
`;
